var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "indexPage",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "header",
    class: _vm.showHeadBg && 'showBg'
  }, [_c('div', {
    staticClass: "header-main"
  }, [_c('div', {
    staticClass: "logo"
  }, [_c('img', {
    attrs: {
      "src": require('@images/logo.png'),
      "alt": ""
    }
  })]), !_vm.isMobile ? _c('div', {
    staticClass: "menu"
  }, [_c('div', {
    staticClass: "menu-main"
  }, _vm._l(_vm.menuList, function (item, index) {
    return _c('span', {
      key: index,
      class: item.value == 'TOKENOMICS' && !_vm.isMobile && 'TOKENOMICS',
      on: {
        "click": function ($event) {
          return _vm.onAnchor(item.value);
        }
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 0)]) : _vm._e(), _c('div', {
    staticClass: "share"
  }, [_c('div', {
    staticClass: "share-item",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onSkip('twitter');
      }
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-lfg"
    }
  })], 1), _c('div', {
    staticClass: "share-item",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onSkip('telegram');
      }
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-telegram"
    }
  })], 1), _vm.isMobile ? _c('div', {
    staticClass: "btn-menu",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return (() => _vm.popMenuShow = !_vm.popMenuShow).apply(null, arguments);
      }
    }
  }, [_c('transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.popMenuShow,
      expression: "popMenuShow"
    }],
    staticClass: "pop-menu"
  }, _vm._l(_vm.menuList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "menu-item",
      on: {
        "click": function ($event) {
          return _vm.onAnchor(item.value);
        }
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 0)])], 1) : _vm._e()])])]), _c('div', {
    staticClass: "p1"
  }, [!_vm.isMobile ? _c('video', {
    attrs: {
      "preload": "metadata",
      "autoplay": "",
      "controls": "",
      "controlsList": "nodownload noremoteplyback noplaybackrate",
      "muted": "",
      "loop": "true",
      "disablepictureinpicture": "",
      "src": require('@images/p1.mp4')
    },
    domProps: {
      "muted": true
    }
  }, [_c('source', {
    attrs: {
      "type": "video/mp4"
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "p1-main"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('vue-typed-js', {
    attrs: {
      "typeSpeed": 30,
      "showCursor": false,
      "strings": [_vm.$lang('AI314')]
    }
  }, [_c('span', {
    staticClass: "typing"
  })])], 1), _c('div', {
    staticClass: "subtitle"
  }, [_c('p', {
    staticClass: "wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.2s"
    }
  }, [_vm._v(_vm._s(_vm.$lang("A groundbreaking underlying interaction protocol")))]), _c('p', {
    staticClass: "wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.3s"
    }
  }, [_vm._v(_vm._s(_vm.$lang("Redefining decentralized transactions with AI")))])]), _c('div', {
    staticClass: "btn wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.4s"
    },
    on: {
      "click": function ($event) {
        return _vm.onSkip('twitter');
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang("JOINUS")))])])]), _c('div', {
    staticClass: "p2",
    attrs: {
      "id": "AI314"
    }
  }, [_c('div', {
    staticClass: "p2-main"
  }, [_c('div', {
    staticClass: "p2-left"
  }, [_vm.typedShowList.AI314Show ? _c('vue-typed-js', {
    attrs: {
      "typeSpeed": 20,
      "showCursor": false,
      "strings": [`<span>${_vm.$lang('AI314')}</span> <p>${_vm.$lang('DECENTRALIZED AI')}</p> <p>${_vm.$lang('REVOLUTION')}</p>`],
      "contentType": 'html'
    }
  }, [_c('div', {
    staticClass: "title typing"
  })]) : _vm._e(), _c('div', {
    staticClass: "subtitle wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.5s"
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang(`AI314, a novel protocol on the BSC chain, merges AI with decentralization, innovating blockchain transactions. Simplifying transaction processes and employing an infinite burn model, AI314 incentivizes trading volume, driving token value upward. Our aim is to establish a genuinely decentralized AI model, eliminating traditional complexities and the influence of arbitrage tools like bots and front-runners, ensuring a safer and more efficient trading experience.`)) + " ")])], 1), _c('div', {
    staticClass: "img"
  }, [_c('img', {
    attrs: {
      "src": require('@images/p2-img.jpg'),
      "alt": ""
    }
  })])])]), _c('div', {
    staticClass: "p3",
    attrs: {
      "id": "VISION"
    }
  }, [!_vm.isMobile ? _c('video', {
    attrs: {
      "preload": "metadata",
      "autoplay": "",
      "controls": "",
      "controlsList": "nodownload noremoteplyback noplaybackrate",
      "muted": "",
      "loop": "true",
      "disablepictureinpicture": "",
      "src": require('@images/p3.mp4')
    },
    domProps: {
      "muted": true
    }
  }, [_c('source', {
    attrs: {
      "type": "video/mp4"
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "p3-main"
  }, [_vm.typedShowList.VISIONShow ? _c('vue-typed-js', {
    attrs: {
      "typeSpeed": 30,
      "showCursor": false,
      "strings": [_vm.$lang('THE VISION OF AI314')]
    }
  }, [_c('div', {
    staticClass: "title typing"
  })]) : _vm._e(), _c('div', {
    staticClass: "subtitle"
  }, [_c('p', {
    staticClass: "wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.2s"
    }
  }, [_vm._v(_vm._s(_vm.$lang("In a digital world, AI314 acts as a mysterious digital city, providing robust support and connectivity to the blockchain realm. In this digital city, AI and blockchain converge to build a brand-new ecosystem. AI314's birth signifies the fusion of AI and decentralization, heralding a new digital era.")))])])], 1)]), _c('div', {
    staticClass: "p4",
    attrs: {
      "id": "FEATURES"
    }
  }, [_c('div', {
    staticClass: "p4-main"
  }, [_vm.typedShowList.FEATURESShow ? _c('vue-typed-js', {
    attrs: {
      "typeSpeed": 30,
      "showCursor": false,
      "strings": [_vm.$lang('FEATURES OF AI314')]
    }
  }, [_c('div', {
    staticClass: "title typing"
  })]) : _vm._e(), _c('div', {
    staticClass: "subtitle wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.2s"
    }
  }, [_vm._v(_vm._s(_vm.$lang("AI314 optimizes decentralized transactions by integrating AI, eliminating authorization hassle, and incentivizing participation through its unique burn model.")))]), _c('div', {
    staticClass: "list"
  }, [_c('div', {
    staticClass: "item wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.3s"
    }
  }, [_c('img', {
    attrs: {
      "src": require('@images/p3-icon-1.png'),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "item__title"
  }, [_vm._v(_vm._s(_vm.$lang("Decentralized Protocol")))]), _c('div', {
    staticClass: "item__value"
  }, [_vm._v(_vm._s(_vm.$lang("No need for swap; AI314 enables direct peer-to-peer interaction for instant trades!")))])]), _c('div', {
    staticClass: "item wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.5s"
    }
  }, [_c('img', {
    attrs: {
      "src": require('@images/p3-icon-2.png'),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "item__title"
  }, [_vm._v(_vm._s(_vm.$lang("No Authorization Needed")))]), _c('div', {
    staticClass: "item__value"
  }, [_vm._v(_vm._s(_vm.$lang("AI314 removes cumbersome authorization processes, allowing direct trading between contract addresses, lowering transaction barriers.")))])]), _c('div', {
    staticClass: "item wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.7s"
    }
  }, [_c('img', {
    attrs: {
      "src": require('@images/p3-icon-3.png'),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "item__title"
  }, [_vm._v(_vm._s(_vm.$lang("Incentivized Burn Model")))]), _c('div', {
    staticClass: "item__value"
  }, [_vm._v(_vm._s(_vm.$lang("AI314 adopts an infinite burn model; the higher the trading volume, the more tokens are burned, driving token value upward for greater profits.")))])]), _c('div', {
    staticClass: "item wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.9s"
    }
  }, [_c('img', {
    attrs: {
      "src": require('@images/p3-icon-4.png'),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "item__title"
  }, [_vm._v(_vm._s(_vm.$lang("Resistant to Bots and Front-runners")))]), _c('div', {
    staticClass: "item__value"
  }, [_vm._v(_vm._s(_vm.$lang("AI314 employs unique mechanisms to prevent interference from arbitrage tools like bots and front-runners, ensuring fair and stable transactions.")))])])])], 1)]), _c('div', {
    staticClass: "p5",
    attrs: {
      "id": "TOKENOMICS"
    }
  }, [_c('div', {
    staticClass: "p5-main"
  }, [_vm.typedShowList.TOKENOMICSShow ? _c('vue-typed-js', {
    attrs: {
      "typeSpeed": 30,
      "showCursor": false,
      "strings": [_vm.$lang('TOKENOMICS')]
    }
  }, [_c('div', {
    staticClass: "title typing"
  })]) : _vm._e(), _c('div', {
    staticClass: "p5-info"
  }, [_c('div', {
    staticClass: "p5-info__item"
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang("Token Name")) + ": ")]), _vm._v("$AI314 ")]), _c('div', {
    staticClass: "p5-info__item"
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang("Token Full Name")) + ": ")]), _vm._v("AI314 Protocol ")]), _c('div', {
    staticClass: "p5-info__item"
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang("Token Supply")) + ": ")]), _vm._v("100 Billion ")])]), _c('div', {
    staticClass: "img wow animate__animated animate__bounceIn",
    attrs: {
      "data-wow-delay": "0.5s"
    }
  }, [!_vm.isMobile ? _c('img', {
    attrs: {
      "src": require('@images/echart.png'),
      "alt": ""
    }
  }) : _c('img', {
    attrs: {
      "src": require('@images/wap/echart.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "p5-intro wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.2s"
    }
  }, [_vm._v(_vm._s(_vm.$lang("Transfer BNB to the contract address is buy, transfer AI314 to the contract address is sell, the transaction time is cooled for 60 seconds")))]), _c('div', {
    staticClass: "p5-address wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.3s"
    }
  }, [_vm._v("CA:0xb62370DB783721c4ACa799B4796fD936E61F437")])], 1)]), _c('div', {
    staticClass: "footer"
  }, [_c('div', {
    staticClass: "footer-main"
  }, [_c('div', {
    staticClass: "logo1 wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.2s"
    }
  }, [_c('img', {
    attrs: {
      "src": require('@images/logo1.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "subtitle wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.4s"
    }
  }, [_vm._v(_vm._s(_vm.$lang("Truly Decentralized AI Trading")))]), _c('div', {
    staticClass: "btn wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.6s"
    },
    on: {
      "click": function ($event) {
        return _vm.onSkip('twitter');
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang("JOINUS")))])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };